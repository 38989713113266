import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import roles from '../data/roles.json';
import SearchIcon from '../img/search.svg';
import _ from 'underscore';
import { useEffect, useState } from 'react';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import Meeting from '../img/meeting2-optimised.jpg';
import Footer from '../components/Footer/Footer';


const OpenPositionsPage = () => {

    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedRoleType, setSelectedRoleType] = useState('');
    const [selectedSalary, setSelectedSalary] = useState('');
    const [filteredRoles, setFilteredRoles] = useState(roles);

    const homepageBackground = {
        backgroundImage: `url(${Meeting})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '55vh'
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px'
    }

    const roleCard = {
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)',
        padding: '30px',
        color: '#fff',
        marginBottom: '30px'
    }

    const locationsListRaw = _.pluck(roles, "location");
    const locationsList = _.uniq(locationsListRaw);
    const orderedLocationsList = locationsList.sort();

    const handleLocationChange = (e) => {
        console.log("e.target.value: ", e.target.value);
        setSelectedLocation(e.target.value);
    }

    const roleTypesListRaw = _.pluck(roles, "roleType");
    const roleTypesList = _.uniq(roleTypesListRaw);
    const orderedRoleTypesList = roleTypesList.sort();

    const handleRoleTypeChange = (e) => {
        console.log("e.target.value: ", e.target.value);
        setSelectedRoleType(e.target.value);
    }

    const salaryListRaw = _.pluck(roles, "salary");
    const salaryList = _.uniq(salaryListRaw);
    const orderedSalaryList = salaryList.sort();

    const handleSalaryChange = (e) => {
        console.log("e.target.value: ", e.target.value);
        setSelectedSalary(e.target.value);
    }

    useEffect(() => {

        console.log('useEffect called');

        // selected location
        // selected type
        // selected salary
        // selected location and type
        // selected location and salary
        // selected type and salary
        // all selected
        if (selectedLocation.length > 0 || selectedRoleType.length > 0 || selectedSalary.length > 0) {
            const filteredRolesList = _.where(filteredRoles, { location: selectedLocation });

            console.log(roles.length);
            console.log(filteredRoles.length);
            console.log('filteredRolesList: ', filteredRolesList);

            setFilteredRoles(filteredRolesList);
        }



    }, [selectedLocation, selectedRoleType, selectedSalary]);

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ marginBottom: '30px' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>Open positions</h1>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <Row style={{ paddingTop: '30px' }}>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Row>
                                    <Col>
                                        <InputGroup sm={9}>
                                            <InputGroup.Text><img src={SearchIcon} style={{ height: '30px' }} alt="Search icon" /></InputGroup.Text>
                                            <Form.Control placeholder="Search roles" />
                                        </InputGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <Button type="submit" className="mb-2">
                                            Search roles
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Select aria-label="Default select example" onChange={(e) => { handleLocationChange(e) }}>
                                            <option value="">Location(s)</option>
                                            {orderedLocationsList.map((location, index) => (
                                                <option key={index} value={location}>{location}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Select aria-label="Default select example" onChange={(e) => { handleRoleTypeChange(e) }}>
                                            <option>Role type</option>
                                            {orderedRoleTypesList.map((role, index) => (
                                                <option key={index} value={role}>{role}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Select aria-label="Default select example" onChange={(e) => { handleSalaryChange(e) }}>
                                            <option>Salary</option>
                                            {orderedSalaryList.map((salary, index) => (
                                                <option key={index} value={salary}>{salary}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>


                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>Showing {filteredRoles.length} of {roles.length}</p>
                    </Col>
                </Row>

                <Row style={{ padding: '50px' }}>
                    {filteredRoles.map((role, index) => (
                        <Col key={index} sm={3}>
                            <div style={roleCard}>
                                <h2>{role.roleName}</h2>
                                <p>{role.location} | {role.salaryType === 'fixed-from' ? <span>From {role.salary}</span> : ''}
                                    {role.salaryType === 'fixed-to' ? <span>To {role.salary}</span> : ''}
                                    {role.salaryType === 'range' ? <span>{role.salaryLow} - {role.salaryHigh}</span> : ''}
                                </p>
                                <Button style={buttonStyle}>Read more</Button>
                            </div>
                        </Col>
                    ))}
                </Row>

                <Footer />

            </Container>
        </div>
    );
}

export default OpenPositionsPage;