import { Row, Col } from 'react-bootstrap';
import footerLogo from '../../img/logo/White/Opentech_logo_long_white.png';
import EcologiLogo from '../../img/ecologi.png';

const Footer = () => {


    return (
        <div>
            <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                <Col sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={footerLogo} style={{ height: '50px' }} />
                </Col>
                <Col sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <p><a href="mailto:info@opentech-partners.co.uk" style={{ color: '#fff'}}>info@opentech-partners.co.uk</a></p>
                        <p><a tel="01615523510">0161 552 3510</a></p>
                        <p>Affinity House, 1 Station Vw, Hazel Grove, SK7 5ER</p>
                    </div>
                </Col>
                <Col sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={EcologiLogo} style={{ maxWidth: '100%', height: '100px' }} />
                </Col>
            </Row>
        </div>
    );
}

export default Footer;